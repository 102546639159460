import { blogAppDefId, blogCommentsAppDefId } from '../../constants/apps';
import { BLOG_COMMENTS_WIDGET_ID, RELATED_POSTS_WIDGET_ID } from '../../constants/widgets';

export function createCommentsDefinition({ applicationId, positionY }) {
  return {
    layout: {
      x: 0,
      y: positionY ?? 0,
      width: 980,
      height: 1048,
    },
    type: 'Container',
    skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
    componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
    data: {
      widgetId: BLOG_COMMENTS_WIDGET_ID,
      applicationId: `${applicationId}`,
      appDefinitionId: blogCommentsAppDefId,
      type: 'TPAWidget',
    },
    mobileHints: {
      type: 'MobileHints',
      shouldBeForceConverted: true,
    },
    style: 'tpaw0',
  };
}

export function createRelatedPostsDefinition({ applicationId, positionY }) {
  return {
    layout: {
      x: 0,
      y: positionY ?? 0,
      width: 980,
      height: 380,
    },
    type: 'Container',
    skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
    componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
    data: {
      widgetId: RELATED_POSTS_WIDGET_ID,
      applicationId: `${applicationId}`,
      appDefinitionId: blogAppDefId,
      type: 'TPAWidget',
    },
    mobileHints: {
      type: 'MobileHints',
      shouldBeForceConverted: true,
    },
    style: {
      styleType: 'custom',
      type: 'ComponentStyle',
      skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
      componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
      style: {
        properties: {
          'alpha-param_color_postListWidgetBackgroundColor': '1',
          'param_boolean_blog-isAuthorNameEnabled': false,
          'param_boolean_blog-isCommentCountEnabled': true,
          'param_boolean_blog-isConfigurableLineClampingEnabled': true,
          'param_boolean_blog-isLikeCountEnabled': true,
          'param_boolean_blog-isPostDescriptionEnabled': false,
          'param_boolean_blog-isPostPublishDateEnabled': false,
          'param_boolean_blog-isReadingTimeEnabled': false,
          'param_boolean_blog-isViewCountEnabled': true,
          'param_font_post-pg-grid-post-list-titleFont':
            '{"family":"helvetica-w01-bold","displayName":"Heading 2","style":{"bold":false,"italic":false,"underline":false},"size":18,"preset":"Custom","editorKey":"font_2","fontStyleParam":true}',
          'param_number_layout-post-list-pg-grid-contentHeight': '154',
          'param_number_layout-post-list-pg-grid-contentHeightType': '1',
          'param_number_layout-post-list-pg-grid-imageRatio': '1',
          'param_number_layout-post-list-pg-grid-layoutCardsRowType': '1',
          'param_number_layout-post-list-pg-grid-titleLineCount': 2,
          param_color_postListWidgetBackgroundColor: 'color_11',
        },
        propertiesSource: {
          'alpha-param_color_postListWidgetBackgroundColor': 'value',
          'param_boolean_blog-isAuthorNameEnabled': 'value',
          'param_boolean_blog-isCommentCountEnabled': 'value',
          'param_boolean_blog-isConfigurableLineClampingEnabled': 'value',
          'param_boolean_blog-isLikeCountEnabled': 'value',
          'param_boolean_blog-isPostDescriptionEnabled': 'value',
          'param_boolean_blog-isPostPublishDateEnabled': 'value',
          'param_boolean_blog-isReadingTimeEnabled': 'value',
          'param_boolean_blog-isViewCountEnabled': 'value',
          'param_font_post-pg-grid-post-list-titleFont': 'value',
          'param_number_layout-post-list-pg-grid-contentHeight': 'value',
          'param_number_layout-post-list-pg-grid-contentHeightType': 'value',
          'param_number_layout-post-list-pg-grid-imageRatio': 'value',
          'param_number_layout-post-list-pg-grid-layoutCardsRowType': 'value',
          'param_number_layout-post-list-pg-grid-titleLineCount': 'value',
          param_color_postListWidgetBackgroundColor: 'theme',
        },
        groups: {},
      },
    },
  };
}
