import { WIDGET_ROLES } from '@wix/communities-blog-client-common';
import { EXPERIMENT_SPLIT_POST_PAGE } from '@wix/communities-blog-experiments';
import { blogAppDefId, blogCommentsAppDefId } from '../../constants/apps';
import { TPA_PAGE_ID_POST } from '../../constants/tpa-pages';
import { POST_WIDGET_ID } from '../../constants/widgets';
import concurentEditing from '../concurent-editing';
import experiments from '../experiments';
import { getComponentRef } from '../magic-migration/sdk-utils';
import monitoring from '../monitoring';
import { updateTPASettings } from '../tpa-settings';
import { createCommentsDefinition, createRelatedPostsDefinition } from './component-definitions';

export async function splitPostPage(context) {
  if (!experiments.isEnabled(EXPERIMENT_SPLIT_POST_PAGE)) {
    return;
  }

  if (context.isADI) {
    return;
  }

  await monitoring.toMonitored(
    'post-page-split',
    (async () => {
      await updateTPASettings(context, { postPageSplitEnabled: true });
      await concurentEditing.withApproval(() => addWidgets(context));
    })(),
    false,
  );
}

async function addWidgets(context) {
  const { sdk, appToken } = context;

  const postLayout = await getPostLayout(context);
  if (!postLayout) {
    throw new Error('Could not retrieve the post widget layout');
  }

  const relatedPostsRef = await addRelatedPostsWidget(context, postLayout.y + postLayout.height);
  if (!relatedPostsRef) {
    throw new Error('Could not add the related posts widget');
  }

  const relatedPostsLayout = await sdk.document.components.layout.get(appToken, { componentRef: relatedPostsRef });
  if (!relatedPostsLayout) {
    throw new Error('Could not retrieve the related posts widget layout');
  }

  const commentsRef = await addCommentsWidget(context, relatedPostsLayout.y + relatedPostsLayout.height);
  if (!commentsRef) {
    throw new Error('Could not add the comments widget');
  }

  const relatedPostsConnection = await connectWidgetToPost(context, {
    componentRef: relatedPostsRef,
    role: WIDGET_ROLES.RELATED_POSTS,
  });
  if (!relatedPostsConnection) {
    throw new Error('Could not connect the related posts widget');
  }

  const commentsConnection = await connectWidgetToPost(context, {
    componentRef: commentsRef,
    role: WIDGET_ROLES.COMMENTS,
  });
  if (!commentsConnection) {
    throw new Error('Could not connect the comments widget');
  }
}

async function addCommentsWidget(context, positionY) {
  const { sdk, appToken } = context;

  await sdk.tpa.add.application(appToken, { appDefinitionId: blogCommentsAppDefId });
  const pageRef = await sdk.tpa.getPageRefByTPAPageId(appToken, { tpaPageId: TPA_PAGE_ID_POST });
  const appData = await sdk.tpa.app.getDataByAppDefId(appToken, blogCommentsAppDefId);
  const componentDefinition = createCommentsDefinition({
    applicationId: appData.applicationId,
    positionY,
  });
  const componentRef = await sdk.document.components.add(appToken, { componentDefinition, pageRef });
  return componentRef;
}

async function addRelatedPostsWidget(context, positionY) {
  const { sdk, appToken } = context;

  const pageRef = await sdk.tpa.getPageRefByTPAPageId(appToken, { tpaPageId: TPA_PAGE_ID_POST });
  const appData = await sdk.tpa.app.getDataByAppDefId(appToken, blogAppDefId);
  const componentDefinition = createRelatedPostsDefinition({
    applicationId: appData.applicationId,
    positionY,
  });
  const componentRef = await sdk.document.components.add(appToken, { componentDefinition, pageRef });
  return componentRef;
}

async function getPostLayout(context) {
  const { sdk, appToken } = context;

  const componentRef = await getComponentRef(sdk, POST_WIDGET_ID);
  if (!componentRef) {
    return;
  }
  const layout = await sdk.document.components.layout.get(appToken, { componentRef });
  return layout;
}

async function connectWidgetToPost(context, { componentRef, role }) {
  const { sdk, appToken } = context;

  const postComponentRef = await getComponentRef(sdk, POST_WIDGET_ID);
  if (!postComponentRef) {
    return;
  }

  const connectionData = await sdk.document.controllers.connect(appToken, {
    connectToRef: componentRef,
    controllerRef: postComponentRef,
    role,
    isPrimary: false,
  });

  return connectionData;
}
