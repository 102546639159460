import { request, setInstance } from './request';

export async function updateTPASettings(context, settings) {
  const { sdk } = context;

  const instance = await sdk.document.info.getAppInstance();
  if (!instance) {
    return;
  }

  setInstance(instance);
  const response = await request.patch(
    '/v1/settings/instances',
    {
      settings,
      host: 'NA',
      states: ['PUBLISHED'],
    },
    {
      apiBaseUrl: '/_api/app-settings-service',
    },
  );

  return response;
}
